import React, { useRef } from "react";
import "./styles/App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import MainSection from "./components/MainSection";
import SubscriptionSection from "./components/SubscriptionSection";
import Footer from "./components/Footer";
import Rights from "./components/Rights";

function App() {
  // Explicitly type the ref as a reference to an HTMLDivElement
  const subscriptionRef = useRef<HTMLDivElement | null>(null);

  const scrollToSubscription = () => {
    // The optional chaining operator (?) ensures that scrollIntoView is only called if subscriptionRef.current is not null.
    subscriptionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header
        logoSrc="/LOGOBIG.svg" // Replace with your logo path
        buttonText="Subscribe"
        onButtonClick={scrollToSubscription}
      />
      <Hero />
      <MainSection />
      <div ref={subscriptionRef}>
        <SubscriptionSection />
      </div>
      <Footer />
      <Rights />
    </div>
  );
}

export default App;
