import React from "react";
import "../styles/Header.css"; // Link to the CSS file for styling

interface HeaderProps {
  logoSrc: string; // Path to the logo image
  buttonText: string; // Text for the button
  onButtonClick: () => void; // Function to handle button click
}

const Header: React.FC<HeaderProps> = ({
  logoSrc,
  buttonText,
  onButtonClick,
}) => {
  return (
    <header className="site-header">
      <div className="logo-container">
        <img src={logoSrc} alt="Logo" />
      </div>
      <div className="button-container">
        <button onClick={onButtonClick}>{buttonText}</button>
      </div>
    </header>
  );
};

export default Header;
