import React from "react";
import "../styles/Footer.css"; // Ensure your CSS file path is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <a
        href="https://www.linkedin.com/company/styleswipe/"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} /> Linkedin
      </a>
      <a
        href="https://twitter.com/styleswipe_app"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} /> Twitter
      </a>
      <a
        href="https://www.instagram.com/styleswipe_app?igsh=MWo0am9lY3c0N3p3OQ%3D%3D&utm_source=qr"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} /> Instagram
      </a>
      <a
        href="https://www.tiktok.com/@styleswipe.app"
        className="link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTiktok} /> Tiktok
      </a>
    </footer>
  );
};

export default Footer;
