import React from "react";
import "../styles/MainSection.css"; // Path to your CSS file

const TitleSVG = `${process.env.PUBLIC_URL}/LOGOBIG.svg`;
const MockupWebP = `${process.env.PUBLIC_URL}/iPhone15Mockup.webp`;
const MockupPNG = `${process.env.PUBLIC_URL}/iPhone15Mockup.png`;
const BackImage = `${process.env.PUBLIC_URL}/HeroSubBack.webp`;

const MainSection = () => {
  return (
    <div className="main-section">
      <div
        className="text-container"
        // style={{ backgroundImage: `url(${BackImage})` }}
      >
        <p className="subtitle">
          Stop swapping <span className="highlight">cash</span>,
          <span className="desktop-break">
            <br />
          </span>
          <span className="mobile-space"> </span>Start swapping clothes.
        </p>
        <div
          className="back-image-container"
          style={{ backgroundImage: `url(${BackImage})` }}
        ></div>
        <div className="title-svg-container">
          <img src={TitleSVG} alt="Title" className="title-svg" />
        </div>
        <p className="paragraph">
          StyleSwipe is taking down fast fashion, one swipe at a time. Join our
          eco-conscious community where style meets sustainability. With just a
          swipe, you can match with locals tastemakers to trade outfits,
          refreshing your wardrobe without the waste. No more buyer's remorse,
          just endless new looks.
        </p>
      </div>
      <picture className="image-container">
        <source srcSet={MockupWebP} type="image/webp" />
        <img src={MockupPNG} alt="Mockup" className="main-image" />
      </picture>
    </div>
  );
};

export default MainSection;
