import React from "react";
import "../styles/Rights.css"; // Path to your CSS file

const Rights = () => {
  return (
    <div className="body-bottom">
      <p className="text">© 2024 StyleSwipe, LLC, All Rights Reserved.</p>
    </div>
  );
};

export default Rights;
