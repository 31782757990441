import React, { useState } from "react";
import "../styles/SubscriptionSection.css";

// const BackgroundImage = `${process.env.PUBLIC_URL}/Background_3_27.png`;

const SubscriptionSection: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<"error" | "success" | "">("");

  // Correct the type of event here
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await fetch("/.netlify/functions/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Subscription failed. Please try again.");
      }

      const result = await response.json();
      setMessage(result.message);
      setMessageType("success"); // For successful subscription
      setEmail(""); // Optionally reset email field after a successful subscription

      // Set a timeout to clear the message after 5 seconds
      setTimeout(() => {
        setMessage("");
        setMessageType(""); // Reset message type as well
      }, 5000);
    } catch (error) {
      console.error("Subscription error:", error);
      setMessage("Failed to subscribe. Please try again later.");
      setMessageType("error"); // For errors
      // Set a timeout to clear the message after 5 seconds
      setTimeout(() => {
        setMessage("");
        setMessageType(""); // Reset message type as well
      }, 5000);
    }
  };

  return (
    <div
      className="subscription-section"
      // style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <h2 className="subscription-header">
        Can't wait to start swapping? Join our early access list*
      </h2>
      <h1 className="suscription-subheader">
        Be the first to know when StyleSwipe goes live and get exclusive access
        to sneak peeks, updates, and special launch-day surprises.{" "}
      </h1>
      <form onSubmit={handleSubmit}>
        <input
          className="text-input"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          required
        />
        <button type="submit" disabled={!email || messageType !== ""}>
          Subscribe
        </button>
      </form>
      {message && (
        <p
          className={`${
            messageType === "error" ? "message-error" : "message-success"
          } ${message ? "show" : ""}`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default SubscriptionSection;
