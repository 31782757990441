import React from "react";
import "../styles/Hero.css"; // Path to your CSS file

const heroImageWebP = `${process.env.PUBLIC_URL}/heroimage2.webp`;
const heroImagePNG = `${process.env.PUBLIC_URL}/heroimage2.png`;

const Hero = () => {
  return (
    <div className="hero-section">
      <picture>
        <source srcSet={heroImageWebP} type="image/webp" />
        <img src={heroImagePNG} alt="Hero" className="hero-image" />
      </picture>
    </div>
  );
};

export default Hero;
